import React from "react"

import Layout from "../components/layout"
import SEOComponent from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEOComponent  title="Home" />
    <div className="columns m-0 pt-4">
      <div className="column">
        <h1 className="title is-1">Welcome to pob3d.com</h1>
        <p>This is Paul O’Brien’s art site. I am a 3D Artist, specializing in 3D Character Design, Rigging, and Animation.</p>
      </div>
    </div>
  </Layout>
)

export default IndexPage
